<template>
	<div
		:id="blockId"
		class="block-ecwid-store"
		data-qa="builder-section-onlinestore"
	>
		<div
			v-if="showStore"
			:id="`my-store-${storeId}`"
			ref="store"
			data-qa="onlinestore-section-ecwid"
		/>
	</div>
</template>

<script>
/**
 * TODO: IMPROVEMENTS
 * Store page gets cached
 * Filter modal in preview mode goes full screen (test in live site if does not overlay)
 * Mobile modee not really responsive - https://github.com/zyro-inc/zyro/issues/1840
 * https://github.com/zyro-inc/zyro/issues/1900
 * https://github.com/zyro-inc/zyro/issues/1997 ?
 * https://github.com/zyro-inc/zyro/issues/2031
 * https://github.com/zyro-inc/zyro/issues/2589
 * https://github.com/zyro-inc/zyro/issues/2851
 * https://github.com/zyro-inc/zyro/issues/2865
 * https://github.com/zyro-inc/zyro/issues/2866
 * https://github.com/zyro-inc/zyro/issues/2895
 * https://github.com/zyro-inc/zyro/issues/2982
 */

import { mapState } from 'vuex';

import ecwidScript from './ecwidScript';

const DEFAULT_STORE_ID = '26103239';

/**
 * Taken from builderRoot.vue .builder-root__main transition
 * TODO: For all common CSS/SASS/JS variables (including this one), move them out.
 * https://www.bluematador.com/blog/how-to-share-variables-between-js-and-sass
 */

const ANIMATION_TIME = 400;

export default {
	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return { showStore: true };
	},
	computed: {
		...mapState(['website']),
		...mapState('gui', ['isMobileView']),
		storeId() {
			const {
				ecwidStoreId,
				demoEcwidStoreId,
			} = this.website.meta;

			return ecwidStoreId || demoEcwidStoreId || DEFAULT_STORE_ID;
		},
	},
	watch: {
		async storeId(newId) {
			if (this.website.meta.demoEcwidStoreId === newId) {
				return;
			}

			// Delete previous store
			this.$refs.store.innerHTML = '';
			// Wait until template id changes
			await this.$nextTick();
			this.startLoadingEcwidScript();
		},
		isMobileView() {
			this.showStore = false;

			setTimeout(() => {
				this.showStore = true;
				ecwidScript.load(this.storeId);
			}, ANIMATION_TIME);
		},
	},
	mounted() {
		this.startLoadingEcwidScript();
	},
	methods: {
		async startLoadingEcwidScript() {
			try {
				await ecwidScript.preload(this.storeId);
			} catch {
				/*
				 * if ecwid preload fails to load, try to load full ecwid solution
				 * since it adds just variables and injects script in head
				 * can't handle future errors from here anymore
				 */
				ecwidScript.load(this.storeId);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@user/components/block-ecwid-store/BlockEcwidStore";
</style>

<style lang="scss">
@import "@user/components/block-ecwid-store/BlockEcwidStoreGlobal";

/**
	Force hide cookie popup in builder
	Would have to apply styling classes (e.g.: ec-notices--fixed) to increase specificity,
	but those may change, so I'm using !important
*/

html#ecwid_html > body#ecwid_body .block-ecwid-store .ec-notices {
	display: none !important;
}
</style>
